import React from "react";
import styled from "styled-components";
import {
  subFont,
  mainWhite,
  transHover,
  mainPurple,
  screen,
  darkPurple,
} from "../variables";
import { StaticImage } from "gatsby-plugin-image";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { motion, AnimatePresence } from "framer-motion";
import { ScrollLocky } from "react-scroll-locky";
import Button from "../button";

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const item = {
  hidden: { opacity: 0, x: -15 },
  show: { opacity: 1, x: 0 },
};

const Wrapper = styled(motion.div)`
  background: rgba(46, 41, 78, 0.98);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 21;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    background: ${mainWhite};
    border-radius: 16px;
    max-width: 1000px;
    width: calc(100% - 50px);
    position: relative;
    padding: 50px 25px 50px 25px;
    margin: 0 25px;
    overflow-y: auto;
    @media ${screen.xsmall} {
      display: flex;
      padding: 60px;
      border-radius: 20px;
    }

    @media ${screen.small} {
      padding: 80px;
      border-radius: 25px;
    }

    @media ${screen.medium} {
      padding: 60px 120px 60px 120px;
      border-radius: 30px;
    }

    .col {
      &--left {
        @media ${screen.xsmall} {
          width: 50%;
        }

        .description {
          h3 {
            font-family: ${subFont};
            font-size: 1.25rem;
            margin: 0 0 12px 0;
            @media ${screen.xsmall} {
              font-size: 1.6rem;
            }
            @media ${screen.small} {
              font-size: 1.7rem;
            }
            @media ${screen.medium} {
              font-size: 1.8rem;
            }

            strong {
              color: ${mainPurple};
              font-style: normal;
            }
          }

          p {
            font-size: 1.05rem;
            line-height: 1.4;
            @media ${screen.xsmall} {
              font-size: 1.2rem;
            }
          }
        }

        .img {
          display: none;
          @media ${screen.xsmall} {
            display: block;
            margin: 26px 0 0 75px;
            max-width: 150px;
            transform: scaleX(-1);
          }
        }
      }

      &--right {
        margin: 30px 0 0 0;
        @media ${screen.xsmall} {
          padding: 0 0 0 50px;
          margin: 0;
          width: 50%;
        }

        .form {
          .each-field-wrapper {
            margin: 0 0 16px 0;
            @media ${screen.xsmall} {
              margin: 0 0 25px 0;
            }

            label {
              display: block;
              margin: 0 0 8px 0;
              @media ${screen.xsmall} {
                font-size: 1.2rem;
              }
            }

            input {
              border: 0;
              display: block;
              width: 100%;
              border: 0;
              border-radius: 10px;
              background: #ece0ff;
              font-size: 16px;
              outline: none;
              padding: 12px 13px;
              @media ${screen.xsmall} {
                font-size: 1.2rem;
              }
            }
          }

          .btn {
            margin: 25px 0 0 0;
            @media ${screen.xsmall} {
              margin: 35px 0 0 0;
            }

            button {
              background: ${darkPurple};
              color: ${mainWhite};
              @media ${screen.withCursor} {
                &:hover {
                  background: ${mainPurple};
                }
              }
            }
          }
        }
      }
    }

    .close-btn {
      font-size: 1.96rem;
      position: absolute;
      right: 12px;
      top: 10px;
      cursor: pointer;
      transition: ${transHover};
      @media ${screen.xsmall} {
        font-size: 2.16rem;
        right: 25px;
        top: 25px;
      }
      @media ${screen.withCursor} {
        &:hover {
          color: ${mainPurple};
        }
      }
    }
  }
`;

const DownloadFormSalesforce = ({ isDownloadForm, setIsDownloadForm }) => {
  const isActive = isDownloadForm;

  return (
    <AnimatePresence>
      {isActive && (
        <Wrapper
          id="member-lightbox"
          initial="hidden"
          animate={isActive ? "show" : "hidden"}
          variants={container}
          exit={{ opacity: 0 }}
        >
          <ScrollLocky isolation={false}>
            <div className="container">
              <motion.div variants={item} className="col col--left">
                <div className="description">
                  <h3>
                    <strong>Salesforce </strong>v HubSpot
                  </h3>
                  <p>
                    Submit your details for instant access to this comparison
                    [PDF download].
                  </p>
                </div>

                <div className="img" role="presentation">
                  <StaticImage
                    src="../../images/DownloadnowIcon-02.png"
                    alt="Brand"
                  />
                </div>
              </motion.div>
              <motion.div variants={item} className="col col--right">
                <form
                  className="js-cm-form form"
                  data-id="191722FC90141D02184CB1B62AB3DC2655C48D80FF889D937F5DC7E3DCD228EEDC726108E751A9A590714057A5B6300E12C29E919D93E0F0F74ADEAF05E86950"
                  action="https://www.createsend.com/t/subscribeerror?description="
                  method="post"
                >
                  <div className="each-field-wrapper">
                    <label>First Name</label>
                    <input
                      type="text"
                      aria-label="First name"
                      id="fieldjrttliu"
                      name="cm-f-jrttliu"
                      required
                    />
                  </div>
                  <div className="each-field-wrapper">
                    <label>Last Name</label>
                    <input
                      type="text"
                      aria-label="Last name"
                      id="fieldjrttldl"
                      name="cm-f-jrttldl"
                      required
                    />
                  </div>
                  <div className="each-field-wrapper">
                    <label>Email Address</label>
                    <input
                      type="email"
                      aria-label="Email"
                      className="js-cm-email-input qa-input-email"
                      name="cm-jltkltr-jltkltr"
                      // id="fieldEmail"
                      required
                    />
                  </div>

                  <Button className="btn">DOWNLOAD NOW</Button>
                </form>
              </motion.div>

              <p
                className="close-btn"
                role="button"
                onClick={() => {
                  setIsDownloadForm(false);
                }}
              >
                <IoIosCloseCircleOutline />
              </p>
            </div>
          </ScrollLocky>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};

export default DownloadFormSalesforce;
